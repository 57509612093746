:root {
    --card-size-height: 600px;
    --card-size-width: 300px;
    /*
    --swiper-navigation-color: var(--swiper-theme-color);
    */
}

.catcard {
    width: 100%;
    display: flex;
    flex-direction: column;

    // justify-self: stretch;

    border: 1px solid transparent;
    transition: 0.2s;

    &:hover {
        border-color: $a2;
    }
    &__topwrap {
        position: relative;
        width: 100%;
        display: block;
    }
    &__image {
        display: block;
        object-fit: contain;
        width: 100%;
        max-height: 280px;
        min-height: 280px;

        @include xs {
            max-height: 200px;
            min-height: 200px;
        }
    }
    &__labels {
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 10px;
    }
    &__titlePrefix{
        font-size: 14px; //VMA 09-10-24
    }
    &__feauters {
        height: 20px;

        @include xs {
            height: 16px;
        }
    }
    &__rating {
        flex-shrink: 0;
    }
    &__brand {
        //margin-bottom: 6px;

        &-img {
            max-width: 130px;
            height: 20px;
            display: block;

            @include xs {
                height: 16px;
            }
        }
        &-title {
            display: block;
            margin-top: 4px;
            //font-size: 14px;
            color: $b7;
            font-weight: bold;
        }
    }
    &__botwrap {
        padding: 6px 12px 12px;

        @include sm {
            padding: 6px;
        }
    }
    &__title {
        color: $b7;
        display: block;
        margin-bottom: 6px;
    }
    &__colors {
        position: absolute;
        bottom: 5px;
        left: 0;
        @include flex(cc);
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: 0.2s;
    }
    &:hover &__colors {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }
    &__color {
        width: 16px;
        height: 10px;
        border-radius: 2px;
        margin-right: 5px;
        border-radius: 2px;
        overflow: hidden;
    }
    &__colortwo {
        @include flex(lc);
        width: 16px;
        border-radius: 2px;
        overflow: hidden;
        margin-right: 5px;
    }
    &__colortwo &__color {
        width: 8px;
        margin-right: 0;
        border-radius: 0;
    }
    &__bottom {
        @include flex(sc);
        flex-wrap: wrap;
    }
    &__prices {
        @include flex(lc);
        margin: 6px 0;
        flex-wrap: wrap;

        @include xs {
        }
    }
    &__price {
        @include h(6);

        span {
            font-weight: normal;
        }
    }
    &__pricenew {
        @include h(6);
        color: $rd;

        span {
            font-weight: normal;
        }
    }
    &__priceold {
        font-size: 10px; //12px;
        position: relative;
        margin-left: 4px; //10px;

        @include xs {
            margin-left: 4px;
            font-size: 10px; //11px;
        }
        &:after {
            width: 106%;
            top: 6px;
            height: 1px;
            background: $rd;
            transform: rotate(-10deg);
            position: absolute;
            left: -3%;
            content: "";
            z-index: 2;
        }
    }
    &__discount {
        font-size: 11px;          // Размер текста для скидки
        color: $rd;               // Цвет скидки (например, красный)
        font-weight: bold;        // Жирный шрифт
        margin-left: 4px;        // Отступ слева от цены
        background-color: $ltg;   // Например, светло-серый фон для выделения
        padding: 1px 4px;         // Внутренние отступы для аккуратного отображения
        border-radius: 4px;       // Радиус для скругления углов

        @include xs {
            font-size: 11px;
            padding: 1px 4px;
        }
    }
    &__fav {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 3;
        top: 5px;
        right: 5px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC45NjMxIDMuMjUwNjVDMTcuMTYzOCAwLjg4NTA0IDEzLjU1MTkgMS4yODE3NyAxMi4yODc2IDMuOTgzOTFDMTEuNzcwNyA1LjA4ODUgMTAuMjI5MyA1LjA4ODUgOS43MTI0MiAzLjk4MzkxQzguNDQ4MDcgMS4yODE3NyA0LjgzNjI1IDAuODg1MDQzIDMuMDM2ODcgMy4yNTA2NkwyLjY5NDUzIDMuNzAwNzNDMS4xMzYzIDUuNzQ5MzEgMS4zNjEyOSA4LjY3MDcxIDMuMjE0MDEgMTAuNDQ2TDEwLjkwNzkgMTcuODE4M0MxMC45NDIzIDE3Ljg1MTIgMTAuOTcyNiAxNy44ODAyIDExIDE3LjkwNjNDMTEuMDI3NCAxNy44ODAyIDExLjA1NzcgMTcuODUxMiAxMS4wOTIxIDE3LjgxODNMMTguNzg2IDEwLjQ0NkMyMC42Mzg3IDguNjcwNzEgMjAuODYzNyA1Ljc0OTMxIDE5LjMwNTUgMy43MDA3MkwxOC45NjMxIDMuMjUwNjVaTTExIDIuODQ2MDdDMTIuOTU4NCAtMC42MjA3OTQgMTcuODExNiAtMS4wMjU0IDIwLjI3NDYgMi4yMTI3NUwyMC42MTcgMi42NjI4MkMyMi43IDUuNDAxNDIgMjIuMzk5MyA5LjMwNjgxIDE5LjkyMjUgMTEuNjhMMTIuMjA5MiAxOS4wNzA5QzEyLjA5MzkgMTkuMTgxNCAxMS45Njc5IDE5LjMwMjIgMTEuODQ5MSAxOS4zOTU2QzExLjcxMjQgMTkuNTAyOSAxMS41MjE0IDE5LjYyNzIgMTEuMjY2NCAxOS42NzgyQzExLjA5MDUgMTkuNzEzNCAxMC45MDk1IDE5LjcxMzQgMTAuNzMzNiAxOS42NzgyQzEwLjQ3ODYgMTkuNjI3MiAxMC4yODc2IDE5LjUwMjkgMTAuMTUwOSAxOS4zOTU2QzEwLjAzMjEgMTkuMzAyMiA5LjkwNjA5IDE5LjE4MTQgOS43OTA4IDE5LjA3MDlMMi4wNzc1IDExLjY4Qy0wLjM5OTI2NiA5LjMwNjgxIC0wLjcwMDAzNSA1LjQwMTQyIDEuMzgzMDUgMi42NjI4M0wxLjcyNTM4IDIuMjEyNzZDNC4xODg0NSAtMS4wMjU0IDkuMDQxNjEgLTAuNjIwNzk1IDExIDIuODQ2MDdaIiBmaWxsPSIjRUQxRDI0Ii8+Cjwvc3ZnPgo=")
            center no-repeat;
    }
}

.catlabels {
    @include flex(lc);

    &-new,
    &-hit,
    &-sale {
        height: 20px;
        padding: 0 4px;
        @include flex(cc);
        position: relative;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        min-width: 60px;
        text-align: center;
        margin-bottom: 2px;
        max-width: max-content;

        @include xs {
            height: 16px;
            font-size: 10px;
            min-width: 50px;
        }
        @include xxs {
            min-width: 44px;
        }
        span {
            position: relative;
            z-index: 2;
            display: block;
            line-height: 12px;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        }
    }
    &-new {
        color: $l2;

        &:before {
            background: #ecffed;
        }
    }
    &-hit {
        color: $rd;

        &:before {
            background: $a1;
        }
    }
    &-sale {
        color: $w;

        &:before {
            background: $rd;
        }
    }
}

.catfeauters {
    @include flex(lc);

    &-best,
    &-last,
    &-promo,
    &-avalable,
    &-notavalable {
        height: 20px;
        padding: 0 4px;
        @include flex(cc);
        position: relative;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        min-width: 60px;
        text-align: center;
        line-height: 12px;
        border-radius: 4px;
        margin-bottom: 2px;
        max-width: max-content;

        @include xs {
            height: 16px;
            font-size: 10px;
            min-width: 50px;
        }
    }
    &-best {
        color: $rd;
        background: $a1;
    }
    &-last {
        color: $b7;
        background: $gr;
    }
    &-promo {
        color: $rd;
        background: $a0;
    }
    &-avalable {
        color: $l2;
        background: #f6fcf9;
    }
    &-notavalable {
        color: $b7;
        background: $gr;
    }
}

.catrating {
    @include flex(lc);

    &__stars {
        @include flex(lc);
        margin-right: 8px;
    }
    &__star {
        width: 14px;
        height: 14px;
        margin-right: 2px;

        @include xs {
            width: 10px;
            height: 10px;
            background-size: 10px !important;
        }
        // &-0 {
        //     background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy45NjI2IDUuMzYxMTJDMTMuODU0OCA1LjAyOTE0IDEzLjUzMjUgNC44MDA5NSAxMy4wNzg2IDQuNzM1MDJMOS40Nzg3NyA0LjIxMTg3TDcuODY4ODggMC45NDk4NzZDNy42NjU4NSAwLjUzODUzOCA3LjM0OTI2IDAuMzAyNjEyIDcuMDAwMjQgMC4zMDI2MTJDNi42NTEyMiAwLjMwMjYxMiA2LjMzNDYzIDAuNTM4NTM4IDYuMTMxNjIgMC45NDk4NzZMNC41MjE3OSA0LjIxMTg1TDAuOTIxOTAyIDQuNzM0OTlDMC40Njc5MzQgNC44MDA5MiAwLjE0NTczOCA1LjAyOTE0IDAuMDM3ODY0NyA1LjM2MTA3Qy0wLjA2OTk4MDcgNS42OTMwMyAwLjA1NjU2ODIgNi4wNjcwMSAwLjM4NDk5OSA2LjM4NzE4TDIuOTg5ODkgOC45MjYyNkwyLjM3NDk0IDEyLjUxMTdDMi4yOTQwOSAxMi45ODMzIDIuNDMxNDEgMTMuMjY1MyAyLjU2MDg4IDEzLjQxODhDMi43MTIzMiAxMy41OTg0IDIuOTMzMjMgMTMuNjk3NCAzLjE4Mjk0IDEzLjY5NzRDMy4zNzE3MiAxMy42OTc0IDMuNTcyNzggMTMuNjQyIDMuNzgwNDEgMTMuNTMyOEw3LjAwMDI0IDExLjg0TDEwLjIyMDEgMTMuNTMyN0MxMC40Mjc3IDEzLjY0MTkgMTAuNjI4OCAxMy42OTczIDEwLjgxNzYgMTMuNjk3M0gxMC44MTc3QzExLjA2NzQgMTMuNjk3MyAxMS4yODgzIDEzLjU5ODQgMTEuNDM5NyAxMy40MTg4QzExLjU2OTIgMTMuMjY1MyAxMS43MDY1IDEyLjk4MzIgMTEuNjI1NiAxMi41MTE2TDExLjAxMDYgOC45MjYyOEwxMy42MTU1IDYuMzg3MjRDMTMuOTQ0IDYuMDY3MDcgMTQuMDcwNSA1LjY5MzA1IDEzLjk2MjYgNS4zNjExMloiIGZpbGw9IiNGQ0VBREMiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")
        //         center no-repeat;
        // }
        // &-1 {
        //     background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy45NjI2IDUuMzYxMTJDMTMuODU0OCA1LjAyOTE0IDEzLjUzMjUgNC44MDA5NSAxMy4wNzg2IDQuNzM1MDJMOS40Nzg3NyA0LjIxMTg3TDcuODY4ODggMC45NDk4NzZDNy42NjU4NSAwLjUzODUzOCA3LjM0OTI2IDAuMzAyNjEyIDcuMDAwMjQgMC4zMDI2MTJDNi42NTEyMiAwLjMwMjYxMiA2LjMzNDYzIDAuNTM4NTM4IDYuMTMxNjIgMC45NDk4NzZMNC41MjE3OSA0LjIxMTg1TDAuOTIxOTAyIDQuNzM0OTlDMC40Njc5MzQgNC44MDA5MiAwLjE0NTczOCA1LjAyOTE0IDAuMDM3ODY0NyA1LjM2MTA3Qy0wLjA2OTk4MDcgNS42OTMwMyAwLjA1NjU2ODIgNi4wNjcwMSAwLjM4NDk5OSA2LjM4NzE4TDIuOTg5ODkgOC45MjYyNkwyLjM3NDk0IDEyLjUxMTdDMi4yOTQwOSAxMi45ODMzIDIuNDMxNDEgMTMuMjY1MyAyLjU2MDg4IDEzLjQxODhDMi43MTIzMiAxMy41OTg0IDIuOTMzMjMgMTMuNjk3NCAzLjE4Mjk0IDEzLjY5NzRDMy4zNzE3MiAxMy42OTc0IDMuNTcyNzggMTMuNjQyIDMuNzgwNDEgMTMuNTMyOEw3LjAwMDI0IDExLjg0TDEwLjIyMDEgMTMuNTMyN0MxMC40Mjc3IDEzLjY0MTkgMTAuNjI4OCAxMy42OTczIDEwLjgxNzYgMTMuNjk3M0gxMC44MTc3QzExLjA2NzQgMTMuNjk3MyAxMS4yODgzIDEzLjU5ODQgMTEuNDM5NyAxMy40MTg4QzExLjU2OTIgMTMuMjY1MyAxMS43MDY1IDEyLjk4MzIgMTEuNjI1NiAxMi41MTE2TDExLjAxMDYgOC45MjYyOEwxMy42MTU1IDYuMzg3MjRDMTMuOTQ0IDYuMDY3MDcgMTQuMDcwNSA1LjY5MzA1IDEzLjk2MjYgNS4zNjExMloiIGZpbGw9IiNFRDFEMjQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")
        //         center no-repeat;
        // }
    }
    &__count {
        @extend %label;
        color: $b3;

        @include xs {
            font-size: 12px;
        }
    }
}

.catslider {
    padding: 0 0 40px;

    @include sm {
        padding-bottom: 40px;
    }
    &-top {
        @include flex(sc);
        margin-bottom: 30px;
        width: 100%;
    }
    &-heading {
        @include h(2);
        margin-right: 10px;
    }
    &-topright {
        flex-shrink: 0;
    }
    &-tabs {
        margin-bottom: 1rem;
    }
    &-slider {
        position: relative;

        display: flex;
        align-items: stretch;

        .swiper-container {
            overflow: auto;
        }
        .swiper-container-initialized.swiper-container {
            overflow: hidden;
            width: 100%;
        }

        .swiper-slide {
            &--single {
                display: flex;
                align-items: stretch;
                align-content: stretch;
            }
            &--multiple {
                display: grid;
                align-items: stretch;
                grid-template-rows: 1fr 2fr;
            }

            @include col(2, 0);

            @include lg {
                @include col(2.4, 0);
            }
            @include md {
                @include col(4, 0);
            }
            @include xs {
                @include col(6, 0);
            }
        }
    }
    .swiper-container {
        @include lg {
            padding-bottom: 40px;
            margin-left: -20px;
            margin-right: -20px;
        }
        @include md {
            padding-bottom: 24px;
            margin-left: -12px;
            margin-right: -12px;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        background: $a1;
        height: 44px;
        width: 44px;

        @include lg {
            display: none;
        }
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            filter: grayscale(1);
            content: "";
            transition: 0.2s;
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzE0MDM5IDkuMjQyNDJDMC4zMTk2ODYgOS4yNDI0MiAwIDkuNTYyMTEgMCA5Ljk1NjQ2QzAgMTAuMzQ5MyAwLjMxNzM0OCAxMC42Njg0IDAuNzEwMTk0IDEwLjY3MDVMMTYuODgzMSAxMC43NTc2TDEzLjAzNDggMTQuMzQ5M0MxMi43MDE3IDE0LjY2MDIgMTIuNzAxNyAxNS4xODgzIDEzLjAzNDggMTUuNDk5MkMxMy4zMzcgMTUuNzgxMiAxMy44MDU5IDE1Ljc4MTIgMTQuMTA4IDE1LjQ5OTJMMTkuNjA4NCAxMC4zNjU1QzE5LjgyMDIgMTAuMTY3OSAxOS44MjAyIDkuODMyMTQgMTkuNjA4NCA5LjYzNDQ3TDE0LjEwOCA0LjUwMDgzQzEzLjgwNTkgNC4yMTg4MiAxMy4zMzcgNC4yMTg4MiAxMy4wMzQ4IDQuNTAwODNDMTIuNzAxNyA0LjgxMTc0IDEyLjcwMTcgNS4zMzk3OCAxMy4wMzQ4IDUuNjUwNjlMMTYuODgzMSA5LjI0MjQySDAuNzE0MDM5WiIgZmlsbD0iI0YxMEIxMyIvPgo8L3N2Zz4K")
                center no-repeat;
        }
        &:hover:after {
            filter: none;
        }
    }
    .swiper-button-prev {
        transform: scaleX(-1);
        left: -2px;
    }
    .swiper-button-next {
        right: -2px;
    }
    .swiper-pagination {
        display: none;
        bottom: 0;

        @include lg {
            display: block;
        }
    }
    .swiper-pagination-bullet {
        background: $bg;
        width: 10px;
        height: 10px;

        @include xs {
            width: 8px;
            height: 8px;
            margin: 2px !important;
        }
    }
    .swiper-pagination-bullet-active {
        background: $rd;
    }
}

.cattabs {
    margin-bottom: 30px;

    @include lg {
        margin-left: -20px;
        margin-right: -20px;
    }
    @include md {
        margin-left: -12px;
        margin-right: -12px;
    }
    &__wrap {
        @include flex(lc);

        @include lg {
            display: block;
            overflow: auto;
            white-space: nowrap;
            padding-left: 20px;
            padding-bottom: 10px;
        }
        @include md {
            padding-left: 12px;
        }
    }
    &__link {
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-right: 44px;
        display: block;
        border-bottom: 2px solid transparent;
        color: $b3;
        text-transform: uppercase;
        padding-bottom: 5px;

        @include lg {
            margin-right: 30px;
            display: inline-block;
        }
        @include xs {
            margin-right: 14px;
        }
        &--active {
            color: $b;
            border-color: $rd !important;
        }
        &:hover {
            border-color: $gr;
        }
    }
}

.catalog {
    padding-bottom: 100px;

    &-top {
        @include flex(lt);
        flex-wrap: wrap;
    }
    &-heading {
        @include h(2);
        margin-bottom: 30px;
        margin-right: 10px;
    }
    &-count {
        color: $b7;
    }
    &-row {
        @include row(10);

        @include lg {
            @include row(0);
        }
    }
    &-left {
        @include col10(2, 10);

        @include lg {
            @include col10(10, 0);
        }
    }
    &-right {
        @include col10(8, 10);

        @include lg {
            @include col10(10, 0);
        }
    }
    &-menu {
        background: $a0;
        border: 1px solid $a1;
        margin-bottom: 20px;

        @include lg {
            background: transparent;
            border: 0;
            @include flex(lt);
            white-space: nowrap;
            overflow: auto;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            padding-bottom: 15px;
            margin-bottom: 10px;
        }
        @include md {
            margin-left: -12px;
            margin-right: -12px;
            padding-left: 12px;
        }
        &__parent {
            border-bottom: 1px solid $a1;

            @include lg {
                @include flex(lt);
                border-bottom: 0;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
        &__item {
            position: relative;
        }
        &__link {
            padding: 11px 12px;
            padding-right: 40px;
            color: $b;
            display: block;

            @include lg {
                padding: 7px 12px;
                border-radius: 30px;
                border: 1px solid $b;
                margin-right: 10px;
            }
        }
        &__submenu {
            padding-left: 12px;
            padding-bottom: 12px;

            @include lg {
                padding: 0;
                @include flex(lt);
            }
        }
        &__submenu &__link {
            padding: 8px 12px;

            @include lg {
                padding: 7px 12px;
            }
        }
        &__toggle {
            right: 0;
            top: 0;
            position: absolute;
            flex-shrink: 0;
            width: 40px;
            height: 100%;
            display: block;
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC4wMSIvPgo8bWFzayBpZD0ibWFzazAiIG1hc2stdHlwZT0iYWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjEiIHk9IjMiIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij4KPHBhdGggZD0iTTYuMDAwMDQgNC43MDcwNkwyLjM1MzU5IDguMzUzNTFMMS42NDY0OCA3LjY0NjRMNi4wMDAwNCAzLjI5Mjg1TDEwLjM1MzYgNy42NDY0TDkuNjQ2NDggOC4zNTM1MUw2LjAwMDA0IDQuNzA3MDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazApIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWxsPSIjRjEwQjEzIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")
                center no-repeat;
            filter: grayscale(1) brightness(0);
            transform: scaleY(-1);
            transition: 0.2s;

            @include lg {
                display: none;
            }
        }
        &__link:hover &__toggle {
            filter: none;
        }
        &__parent--open &__toggle {
            transform: none;
        }
    }
    &-filter {
        background: $a0;
        border: 1px solid $a1;
        margin-bottom: 30px;

        @include lg {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
            transition: transform 0.2s, opacity 0.2s;
            transform: translateY(5%);
        }
        &--showed {
            @include lg {
                opacity: 1;
                z-index: 16;
                visibility: visible;
                transform: translateY(0%);
            }
        }
        &__wrap {
            @include lg {
                padding-top: 70px;
                padding-bottom: 70px;
                overflow: auto;
                height: 100%;
            }
            @include sm {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
        &__mobtop {
            display: none;

            @include lg {
                @include flex(lc);
                padding: 0 12px;
                height: 60px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                background: $w;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
            }
            @include sm {
                height: 50px;
            }
        }
        &__mobheading {
            @include h(2);
        }
        &__close {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 4;

            @include sm {
                top: 5px;
                right: 5px;
            }
            &:before {
                width: 24px;
                height: 2px;
                transform-origin: center;
                transform: rotate(45deg);
                background: $rd;
                content: "";
                position: absolute;
                top: 18px;
                left: 7px;
            }
            &:after {
                width: 24px;
                height: 2px;
                transform-origin: center;
                transform: rotate(-45deg);
                background: $rd;
                content: "";
                position: absolute;
                top: 18px;
                left: 7px;
            }
        }
        &__block {
            border-bottom: 1px solid $a1;
            -webkit-tap-highlight-color: transparent;
        }
        &__top {
            position: relative;
            cursor: pointer;
            @include flex(lc);

            &right {
                margin-left: auto;
                flex-shrink: 0;
                padding-right: 12px;

                .form-switchbox {
                    margin: 0;
                }
            }
        }
        &__heading {
            padding: 11px 12px;
            padding-right: 40px;
            font-weight: 600;
        }
        &__top:hover &__heading {
            color: $rd;
        }
        &__toggle {
            right: 0;
            top: 0;
            position: absolute;
            flex-shrink: 0;
            width: 40px;
            height: 100%;
            display: block;
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC4wMSIvPgo8bWFzayBpZD0ibWFzazAiIG1hc2stdHlwZT0iYWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjEiIHk9IjMiIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij4KPHBhdGggZD0iTTYuMDAwMDQgNC43MDcwNkwyLjM1MzU5IDguMzUzNTFMMS42NDY0OCA3LjY0NjRMNi4wMDAwNCAzLjI5Mjg1TDEwLjM1MzYgNy42NDY0TDkuNjQ2NDggOC4zNTM1MUw2LjAwMDA0IDQuNzA3MDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazApIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWxsPSIjRjEwQjEzIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")
                center no-repeat;
            filter: grayscale(1) brightness(0);
            transform: scaleY(-1);
            transition: 0.2s;
        }
        &__top:hover &__toggle {
            filter: none;
        }
        &__block--open &__toggle {
            transform: none;
        }
        &__prices {
            padding: 12px;

            &items {
                @include row(6);
            }
            &item {
                @include col(6, 6);
                position: relative;
            }
            &input {
                background: $a1;
                border: 0;
                padding-left: 30px;
                font-size: 14px;
                font-weight: bold;
                min-width: inherit;
                width: 100%;
                height: 32px;
                border-radius: 6px;
            }
            &label {
                font-size: 14px;
                position: absolute;
                left: 7px;
                top: 50%;
                margin-top: -9px;
                height: 16px;
            }
        }
        &__slider {
            margin-top: 20px;
            margin-left: 6px;
            margin-right: 6px;
            position: relative;

            &dot {
                position: absolute;
                top: -5px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                border: 2px solid $rd;
                // margin-left: -6px;
                background: $w;
                cursor: pointer;
            }
            &line {
                background: $rd;
                // width: 100%;
                height: 2px;
            }
        }
        &__content {
        }
        &__items {
            max-height: 230px;
            overflow: auto;
            @extend %scrollr;

            @include lg {
                overflow: inherit;
                max-height: 100%;
            }
        }
        &__item {
            padding: 0 12px;
            position: relative;
            @include flex(lc);
            user-select: none;

            &:hover {
                background: $a1;
            }
            .form-checkbox {
                margin: 0;
                width: 100%;

                &__label:after,
                &__label:before {
                    top: 9px;
                }
            }
            .form-radio {
                margin: 0;
                width: 100%;

                &__label:after {
                    top: 14px;
                }
                &__label:before {
                    top: 9px;
                }
            }
            &label {
                @include flex(lc);
                width: 100%;
                min-height: 100%;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            &color {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                margin-right: 7px;
                flex-shrink: 0;
                border: 1px solid $a2;
            }
            &name {
                width: 100%;
            }
            &count {
                margin-left: auto;
                flex-shrink: 0;
                font-size: 12px;
                color: $b3;
            }
        }
        &__btns {
            padding: 12px;

            @include lg {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 2;
                background: $a0;
            }
            @include sm {
                padding: 6px 12px;
            }
            &row {
                @include row(6);
            }
            &col {
                @include col(6, 6);
            }
        }
        &__submit,
        &__reset {
            min-width: inherit !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 100%;
        }
    }
    &-items {
        position: relative;
        overflow: hidden;

        @include md {
            margin-left: -6px;
            margin-right: -6px;
        }
        &:after {
            width: calc(100% + 14px);
            left: -7px;
            bottom: 12px;
            content: "";
            background: $w;
            position: absolute;
            height: 1px;
        }
    }
    &-cards {
        @include row(6);

        @include md {
            @include row(0);
        }
        .catcard {
            @include col(3, 6);
            margin-bottom: 25px;
            position: relative;
            max-width: var(--card-size-width);
            max-height: var(--card-size-height);

            @include md {
                @include col(3, 0);
            }
            @include sm {
                @include col(4, 0);
            }
            @include xs {
                @include col(6, 0);
            }
            &:after {
                width: calc(100% + 14px);
                height: 1px;
                background: $a1;
                position: absolute;
                bottom: -14px;
                left: -7px;
                content: "";
            }
        }
    }
    &-sortperview {
        @include flex(sc);
        margin-bottom: 20px;
    }
    &-sort {
        max-width: 280px;
        width: 100%;

        select {
        }
    }
    &-mob-filters {
        display: none;
        margin-left: 12px;
        @include lg {
            display: block;
        }
    }
    &-btnfilter {
        display: none;
        margin-left: 12px;
        min-width: 100px !important;
        padding-left: 15px;
        padding-right: 15px;
        flex-shrink: 0;

        &:before {
            content: "";
            background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNTEyIiBmaWxsPSIjZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGlkPSJMYXllcl82MiIgZGF0YS1uYW1lPSJMYXllciA2MiI+PHBhdGggZD0ibTMgNmgxMWEyIDIgMCAwIDAgMiAyaDJhMiAyIDAgMCAwIDItMmgxYTEgMSAwIDAgMCAwLTJoLTFhMiAyIDAgMCAwIC0yLTJoLTJhMiAyIDAgMCAwIC0yIDJoLTExYTEgMSAwIDAgMCAwIDJ6bTEzLTJoMnYxIDFoLTJ6bTUgN2gtOWEyIDIgMCAwIDAgLTItMmgtMmEyIDIgMCAwIDAgLTIgMmgtM2ExIDEgMCAwIDAgMCAyaDNhMiAyIDAgMCAwIDIgMmgyYTIgMiAwIDAgMCAyLTJoOWExIDEgMCAwIDAgMC0yem0tMTMgMnYtMmgydjEgMXptMTMgNWgtM2EyIDIgMCAwIDAgLTItMmgtMmEyIDIgMCAwIDAgLTIgMmgtOWExIDEgMCAwIDAgMCAyaDlhMiAyIDAgMCAwIDIgMmgyYTIgMiAwIDAgMCAyLTJoM2ExIDEgMCAwIDAgMC0yem0tNyAydi0yaDJ2MSAxeiIvPjwvZz48L3N2Zz4=")
                center no-repeat;
            background-size: 22px;
            width: 20px;
            height: 20px;
            display: block;
            margin-right: 10px;
            flex-shrink: 0;
        }
        @include lg {
            @include flex(cc);
        }
    }
    &-perview {
        @include flex(lc);

        @include lg {
            display: none;
        }
        &__label {
        }
        &__item {
            font-weight: 600;
            margin-left: 15px;
            color: $b;

            &--active {
                color: $rd;
            }
        }
    }
    &-sorttags {
        margin-bottom: 10px;

        &__items {
            @include flex(lt);
            flex-wrap: wrap;

            @include sm {
                white-space: nowrap;
                overflow: auto;
                flex-wrap: nowrap;
                margin-left: -12px;
                margin-right: -12px;
                padding-left: 12px;
                padding-bottom: 5px;
            }
        }
        &__item,
        &__reset {
            font-size: 14px;
            font-weight: 600;
            padding: 6px 6px 6px 12px;
            border-radius: 30px;
            @include flex(cc);
            position: relative;
            margin-right: 12px;
            margin-bottom: 10px;

            &:after {
                width: 22px;
                height: 22px;
                content: "";
                display: block;
                margin-left: 6px;
                opacity: 0.8;
            }
            &:hover:after {
                opacity: 1;
            }
        }
        &__item {
            background: $rd;
            color: $w !important;

            &:after {
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDBDMTcuMDgzIDAgMjIgNC45MTcgMjIgMTFDMjIgMTcuMDgzIDE3LjA4MyAyMiAxMSAyMkM0LjkxNyAyMiAwIDE3LjA4MyAwIDExQzAgNC45MTcgNC45MTcgMCAxMSAwWk0xNC45NDkgNS41TDExIDkuNDQ5TDcuMDUxIDUuNUw1LjUgNy4wNTFMOS40NDkgMTFMNS41IDE0Ljk0OUw3LjA1MSAxNi41TDExIDEyLjU1MUwxNC45NDkgMTYuNUwxNi41IDE0Ljk0OUwxMi41NTEgMTFMMTYuNSA3LjA1MUwxNC45NDkgNS41WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==")
                    center no-repeat;
            }
        }
        &__reset {
            background: $a1;
            color: $b !important;

            &:after {
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDBDMTcuMDgzIDAgMjIgNC45MTcgMjIgMTFDMjIgMTcuMDgzIDE3LjA4MyAyMiAxMSAyMkM0LjkxNyAyMiAwIDE3LjA4MyAwIDExQzAgNC45MTcgNC45MTcgMCAxMSAwWk0xNC45NDkgNS41TDExIDkuNDQ5TDcuMDUxIDUuNUw1LjUgNy4wNTFMOS40NDkgMTFMNS41IDE0Ljk0OUw3LjA1MSAxNi41TDExIDEyLjU1MUwxNC45NDkgMTYuNUwxNi41IDE0Ljk0OUwxMi41NTEgMTFMMTYuNSA3LjA1MUwxNC45NDkgNS41WiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=")
                    center no-repeat;
            }
        }
    }
}

.showmore {
    background: transparent;
    border: 1px solid $b;
    color: $b;
    margin-bottom: 20px;
    padding-top: 11px;
    padding-bottom: 11px;

    &:hover {
        border-color: $rd;
    }
}

.pagi {
    margin-bottom: 10px;

    &-items {
        @extend %clear-list;
        @include flex(lt);
        flex-wrap: wrap;
    }
    &-item {
        min-width: 44px;
        height: 44px;
        @include flex(cc);
        margin-right: 10px;
        flex-shrink: 0;
        margin-bottom: 10px;
        background-color: transparent;
        color: $b;
        font-weight: bold;
        text-align: center;
        padding: 4px;
        border-radius: 6px;

        @include sm {
            margin-right: 6px;
        }
        &--active {
            background: $rd;
            color: $w !important;
        }
        &:hover {
            color: $rd;
            background: $a0;
        }
    }
}
