@mixin xxl {
    @media screen and (max-width: #{$xxl-width}) {
        @content;
    }
}

@mixin xl {
    @media screen and (max-width: #{$xl-width}) {
        @content;
    }
}

@mixin lg {
    @media screen and (max-width: #{$lg-width}) {
        @content;
    }
}

@mixin md {
    @media screen and (max-width: #{$md-width}) {
        @content;
    }
}

@mixin sm {
    @media screen and (max-width: #{$sm-width}) {
        @content;
    }
}

@mixin xs {
    @media screen and (max-width: #{$xs-width}) {
        @content;
    }
}

@mixin xxs {
    @media screen and (max-width: #{$xxs-width}) {
        @content;
    }
}

@mixin xxxs {
    @media screen and (max-width: #{$xxs-width}) {
        @content;
    }
}

@mixin row($mr) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -#{$mr}px;
    margin-right: -#{$mr}px;
}

@mixin row-flex() {
    display: flex;
    flex-wrap: wrap;
}

@mixin col($colsize, $mrg) {
    width: calc(100% / 12 * #{$colsize} - #{$mrg * 2}px - 0.1px);
    margin-left: #{$mrg}px;
    margin-right: #{$mrg}px;
    word-wrap: break-word;
}

@mixin col10($colsize10, $mrg10) {
    width: calc(100% / 10 * #{$colsize10} - #{$mrg10 * 2}px - 0.1px);
    margin-left: #{$mrg10}px;
    margin-right: #{$mrg10}px;
    word-wrap: break-word;
}

@mixin coloff($coloffset, $mrg2) {
    margin-left: calc(100% / 12 * #{$coloffset} + #{$mrg2}px);
}

@mixin h($s) {
    @if $s == 1 {
        @extend %heading;
        font-size: 54px;
        line-height: 1.2;

        @include xxl {
            font-size: 62px;
        }
        @include xl {
            font-size: 52px;
        }
        @include lg {
            font-size: 42px;
        }
        @include md {
        }
        @include sm {
            font-size: 38px;
        }
        @include xs {
            font-size: 32px;
        }
    } @else if $s == 2 {
        @extend %heading;
        font-size: 40px;
        line-height: 1.3;

        @include xxl {
            font-size: 34px;
        }
        @include xl {
            font-size: 28px;
        }
        @include lg {
            font-size: 26px;
        }
        @include sm {
            font-size: 24px;
        }
        @include xs {
            font-size: 22px;
        }
        @include xxs {
            font-size: 20px;
        }
    } @else if $s == 3 {
        @extend %heading;
        font-size: 30px;
        line-height: 1.3;

        @include xxl {
            font-size: 26px;
        }
        @include xl {
            font-size: 24px;
        }
        @include lg {
            font-size: 22px;
        }
        @include sm {
            font-size: 20px;
        }
        @include xs {
            font-size: 18px;
        }
    } @else if $s == 4 {
        @extend %heading;
        font-size: 24px;
        line-height: 1.4;

        @include xxl {
            font-size: 22px;
        }
        @include xl {
            font-size: 18px;
        }
        @include sm {
            font-size: 16px;
        }
    } @else if $s == 5 {
        @extend %heading;
        font-size: 20px;
        line-height: 1.4;

        @include xl {
        }
        @include sm {
            font-size: 16px;
        }
    } @else if $s == 6 {
        font-family: "Proxima Nova", sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.4;

        @include sm {
            font-size: 16px;
        }
        @include xs {
        }
    } @else {
    }
}

@mixin flex($ff) {
    display: flex;

    @if $ff == lt {
        justify-content: flex-start;
        align-items: flex-start;
    } @else if $ff == lc {
        justify-content: flex-start;
        align-items: center;
    } @else if $ff == lb {
        justify-content: flex-start;
        align-items: flex-end;
    } @else if $ff == ct {
        justify-content: center;
        align-items: flex-start;
    } @else if $ff == cc {
        justify-content: center;
        align-items: center;
    } @else if $ff == cb {
        justify-content: center;
        align-items: flex-end;
    } @else if $ff == rt {
        justify-content: flex-end;
        align-items: flex-start;
    } @else if $ff == rc {
        justify-content: flex-end;
        align-items: center;
    } @else if $ff == rb {
        justify-content: flex-end;
        align-items: flex-end;
    } @else if $ff == st {
        justify-content: space-between;
        align-items: flex-start;
    } @else if $ff == sc {
        justify-content: space-between;
        align-items: center;
    } @else if $ff == sb {
        justify-content: space-between;
        align-items: flex-end;
    } @else if $ff == ls {
        justify-content: flex-start;
        align-items: stretch;
    } @else if $ff == cs {
        justify-content: center;
        align-items: stretch;
    } @else if $ff == rs {
        justify-content: flex-end;
        align-items: stretch;
    } @else if $ff == ss {
        justify-content: space-between;
        align-items: stretch;
    } @else if $ff == sn {
        justify-content: space-between;
        align-items: baseline;
    } @else {
    }
}

@mixin flexcol($fw) {
    @if $fw == w {
        flex-flow: column wrap;
    } @else if $fw == n {
        flex-flow: column nowrap;
    }
}

%container,
.container {
    width: 100%;
    max-width: 1844px;
    margin: 0 auto;
    padding: 0 20px;

    @include xxl {
    }
    @include xl {
    }
    @include lg {
    }
    @include md {
        padding: 0 12px;
    }
}

%heading {
    font-weight: bold;
    @extend %font;
}

%f22 {
    font-size: 22px;

    @include xxl {
        font-size: 20px;
    }
    @include xl {
        font-size: 18px;
    }
    @include xs {
        font-size: 16px;
    }
}

%f14U {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

%label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

%scroll {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;

        @include md {
        }
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
    }
}

%scrollr {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;

        @include md {
        }
    }

    &::-webkit-scrollbar-track {
        background: $a1;
    }

    &::-webkit-scrollbar-thumb {
        background: $rd;
    }
}

%scrollg {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;

        @include md {
            width: 4px;
            height: 4px;
        }
    }

    &::-webkit-scrollbar-track {
        background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
        background: #bbb;
    }
}

%font {
    font-family: "PF Centro Serif Pro", serif;
    font-weight: bold;
}

%clear-list {
    list-style: none;
    padding: 0;
    margin: 0;
}