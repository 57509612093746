.modal-open {
    overflow: hidden;

    .header {
    }
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    @extend %scroll;
    padding: 0 !important;

    .modal-open & {
        overflow: hidden;
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    margin: 0 auto;
    height: 100%;

    @include sm {
    }

    .modal.fade & {
        transition: 0.1s;
        transform: translateY(2%);
    }

    .modal.show & {
        transform: translateY(0%);
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: 100%;

    &:before {
    }
}

.modal-content {
    position: relative;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
    z-index: 4;
    overflow: auto;
    background: $w;
    max-height: 100%;

    @include sm {
        display: block;
    }

    .modal-lg & {
        @extend %scrollg;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(#2c3746, 0.5);
    backdrop-filter: blur(6px);
    transition: 0.3s;

    &.fade {
        opacity: 0;
    }

    &.show {
        opacity: 1;
    }
}

.modal-header {
    padding: 0;
    position: relative;
    width: 100%;

    .modal-lg & {
        background: $w;
    }
}

.modal-close {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 5;
    min-width: inherit !important;
    padding: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    cursor: pointer;

    &:before {
        width: 24px;
        height: 2px;
        transform-origin: center;
        transform: rotate(45deg);
        background: $bg;
        content: "";
        position: absolute;
        top: 18px;
        left: 7px;
        transition: 0.2s;
    }
    &:after {
        width: 24px;
        height: 2px;
        transform-origin: center;
        transform: rotate(-45deg);
        background: $bg;
        content: "";
        position: absolute;
        top: 18px;
        left: 7px;
        transition: 0.2s;
    }
    &:hover:before,
    &:hover:after {
        background: $rd;
    }
}

.modal-title {
    text-align: left;
    @include h(2);
    line-height: 1.2;
    padding-right: 40px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 40px;
    background: $w;

    .modal-lg & {
        color: $b;
        background: $w;
    }

    @include xl {
        padding: 40px 40px;
    }
    @include sm {
        padding: 25px 15px;
    }

    .form-checkbox,
    .form-radio {
        font-weight: 500;
    }
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 30px 0;
    @include h(4);

    p {
        margin: 0;
    }

    @include sm {
        padding: 20px;
    }

    > :not(:first-child) {
        margin-left: 5px;
    }

    > :not(:last-child) {
        margin-right: 5px;
    }
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog.modal-lg {
        max-width: 100%;
    }
    .modal-dialog {
        max-width: 720px;
    }
    .modal-dialog-centered {
        min-height: calc(100% - (40px * 2));
    }
    .modal-sm {
        max-width: 412px;

        .modal-body {
            padding: 30px;
        }
        @include md {
            max-width: 100%;
        }
    }
}

@include sm {
    .modal-dialog {
        margin: 0px;
    }
}

@media (min-width: 1281px) {
    .modal-lg {
        max-width: 1280px;
    }
}

.fade {
    transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.modal-ok {
    text-align: center;

    &__text {
        margin-bottom: 80px;
        opacity: 0.75;

        @include sm {
            margin-bottom: 40px;
        }
    }
}

.modal-default {
    text-align: center;

    &__heading {
        @include h(5);
        margin-bottom: 15px;
    }

    &__text {
        margin-bottom: 30px;

        @include sm {
            margin-bottom: 15px;
        }
    }

    &__btn {
        margin: 10px;
        min-width: 100px !important;
    }
}

.modal-center {
    @include flex(cc);
    @include flexcol(n);
    text-align: center;

    & > div {
        margin-top: 25px;
    }
}

.modal-text {
    margin-bottom: 40px;

    @include xl {
        margin-bottom: 20px;
    }
}

.modal-btn.btn {
    min-width: 200px;
}

.modal-btns {
    .btn {
        margin: 15px;
    }
}

.modal-btm {
    margin-top: 40px;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
}

.form {
    &-row {
        @include row(15);
    }

    &-col {
        @include col(12, 15);

        @include lg {
            @include col(6, 15);
        }
        @include xs {
            @include col(12, 15);
        }
    }

    &-colfull {
        @include col(12, 15);
    }

    &-col4 {
        @include col(4, 15);

        @include sm {
            @include col(12, 0);
        }
    }

    &-col5 {
        @include col(2.4, 15);

        @include sm {
            @include col(12, 0);
        }
    }
    &-label {
        margin-bottom: 4px;

        span {
            color: $rd;
        }
    }
    &-btnwrap {
        display: flex;
        .btn {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-group {
        margin-bottom: 20px;
        position: relative;

        @include xxl {
            margin-bottom: 20px;
        }
        @include sm {
            margin-bottom: 15px;
        }
        &sc {
            @include flex(sc);
        }
        &rc {
            @include flex(rc);

            .form-checkbox {
                margin: 0 !important;
            }
        }
        &end {
            margin-top: 30px;
        }

        &--error {
            .form-label {
                color: $er;
                opacity: 1;
            }

            .form-input,
            .form-textarea {
                border-color: $er;
            }
        }
    }
    &-help-text {
        font-size: 12px;
        &--success {
            color: $l2;
        }
        &--error {
            color: $er;
        }
        &--size-large {
            font-size: 16px;
        }
    }
    &-reviews {
        .catrating__star {
            width: 24px;
            height: 24px;
            background-size: 24px;
            cursor: pointer;
        }
    }
}

.btn,
%btn {
    position: relative;
    display: inline-block;
    padding: 12px 20px;
    color: $w;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    border: 0;
    letter-spacing: 0.2px;
    user-select: none;
    outline: none !important;
    line-height: normal;
    font-family: "Proxima Nova", sans-serif;
    min-width: 200px;
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s;
    background-color: $rd;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

    @include xxl {
    }
    @include xl {
    }
    @include lg {
    }
    @include xs {
        min-width: 150px;
    }

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:disabled,
    &.disabled {
        cursor: default;
        pointer-events: none;
        background: $gr !important;
        color: $b3 !important;
    }

    &:hover {
        text-decoration: none;
        color: $w;
        background-color: $rdh;
    }

    &:active {
        background-color: $rda;
    }

    &-sm {
        padding: 7px;
        min-width: inherit !important;
    }
    &-full {
        width: 100%;
    }
    &-white {
        background: $w;
        color: $b;
    }
    &-accent {
        background-color: $a0;
        color: $b;
    }
    &-ttn {
        text-transform: none;
    }
    &-second {
        background: $a1;
        color: $b;

        &:hover,
        &:active {
            background: $a2;
            color: $b;
        }
    }

    &-default {
        background: none;
        color: $b;

        &:hover,
        &:active {
            background: $a1;
            color: $b;
        }
    }

    &-trans {
        background-color: transparent;
        color: $rd;
        min-width: inherit !important;
    }
    &link {
        font-weight: bold;
        padding-bottom: 3px;
        border: 0;
        border-bottom: 2px solid $rd;
        color: $b;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            border-color: transparent;
            color: $rd;
        }
    }

    &.circle {
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}

input::-ms-clear {
    display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-input,
.form-textarea,
select,
select.select {
    display: block;
    width: 100%;
    padding: 5px 12px;
    background-color: $w;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    color: $b;
    border: 2px solid $a2;
    border-radius: 6px;
    transition: 0.3s;
    outline: none;
    height: 44px;
    text-overflow: ellipsis;
    box-shadow: none;

    @include xxl {
    }
    @include xl {
    }
    @include lg {
    }
    @include sm {
        font-size: 15px;
    }

    &:focus {
        border-color: $a3;
    }

    &:hover {
    }

    &:disabled {
        opacity: 0.9;
        background: $gr;
        border-color: $a2;
    }

    &::placeholder {
    }
    &__showpass {
        font-size: 12px;
        font-weight: bold;
        background: $a1;
        height: 20px;
        top: 50%;
        margin-top: -10px;
        right: 10px;
        padding: 2px 4px;
        border-radius: 2px;
        position: absolute;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            color: $rd;
        }
    }
}

.form-inputwrap {
    position: relative;
}

.form-input {
    -webkit-appearance: none;
}

input,
button {
    outline: none !important;
}

.form-textarea {
    min-height: 148px;
    height: auto;
    padding: 8px 12px;
    resize: none;

    @include xl {
    }
    @include sm {
    }

    &--sm {
        min-height: 75px;
    }
}

.form-checkbox,
.form-radio,
.form-switchbox {
    position: relative;
    @include flex(lt);
    font-weight: normal;
    margin: 15px 0 0;
    text-align: left;
    text-transform: none;
    word-spacing: initial;
    line-height: 1.4;

    &-sm {
        font-size: 14px;
    }
    a {
        text-decoration: underline;
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }
    }
    &__label {
        cursor: pointer;
        font-weight: normal;

        span {
            display: inline-block;
        }
        a {
            color: $b;
            text-decoration: underline;

            &:hover {
                color: $rd;
            }
        }
    }
    &--disabled {
        pointer-events: none;
    }
    &--disabled &__label {
    }
    &__input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
    }
}
.form-checkbox {
    &__label {
        position: relative;
        padding-left: 30px;

        &:before {
            width: 20px;
            height: 20px;
            background: $w;
            border: 2px solid $a2;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            border-radius: 3px;
            transition: 0.2s;
        }
        &:hover:before {
            border-color: $a3;
        }
        &:after {
            width: 20px;
            height: 20px;
            background: $rd
                url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDMuNTAwMTVMNC43NSA3LjI1MDE1TDExLjAwMDMgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==")
                center no-repeat;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            border-radius: 3px;
            z-index: 2;
            opacity: 0;
            transition: 0.2s;
        }
    }
    &__input:checked + &__label {
        &:after {
            opacity: 1;
        }
    }
}
.form-radio {
    &__label {
        position: relative;
        padding-left: 30px;
        padding-top: 2px;

        &:before {
            width: 20px;
            height: 20px;
            background: $w;
            border: 2px solid $a2;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            transition: 0.2s;
            border-radius: 100%;
        }
        &:hover:before {
            border-color: $a3;
        }
        &:after {
            width: 16px;
            height: 16px;
            background: $rd;
            border: 3px solid $w;
            position: absolute;
            left: 2px;
            top: 2px;
            content: "";
            z-index: 2;
            opacity: 0;
            transition: 0.2s;
            border-radius: 100%;
        }
    }
    &__input:checked + &__label {
        &:before {
            background: $rd;
            border-color: $rd;
        }
        &:after {
            opacity: 1;
        }
    }
}

.form-switchbox {
    &__label {
        position: relative;
        width: 36px;
        height: 20px;
        border-radius: 10px;
        background: $a1;

        &:hover {
            background: $a2;
        }
        &:after {
            width: 16px;
            height: 16px;
            background: $w;
            position: absolute;
            left: 2px;
            top: 2px;
            content: "";
            z-index: 2;
            transition: 0.2s;
            border-radius: 100%;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.2);
        }
    }
    &__input:checked + &__label {
        background: $rd !important;

        &:after {
            left: 18px;
        }
    }
}
