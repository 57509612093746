$path: "../fonts/";

@font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
        url("#{$path}ProximaNova-Regular.woff") format("woff"),
        url("#{$path}ProximaNova-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova Regular It"), local("ProximaNova-RegularIt"),
        url("#{$path}ProximaNova-RegularIt.woff") format("woff"),
        url("#{$path}ProximaNova-RegularIt.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
        url("#{$path}ProximaNova-Semibold.woff") format("woff"),
        url("#{$path}ProximaNova-Semibold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
        url("#{$path}ProximaNova-Bold.woff") format("woff"),
        url("#{$path}ProximaNova-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PF Centro Serif Pro";
    src: url("#{$path}PFCentroSerifPro-Bold.woff2") format("woff2"),
        url("#{$path}PFCentroSerifPro-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
