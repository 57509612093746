*,
*::after,
*::before {
    box-sizing: border-box;
    outline: none !important;
}

body {
    min-width: 320px;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: $b;
    background: $w;
    overscroll-behavior: none;

    @include xxl {
    }
    @include xl {
    }
    @include sm {
        font-size: 14px;
        line-height: 1.3;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    cursor: pointer;
    color: $rd;
    transition: 0.2s;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    text-decoration: none;
}

a:hover {
    color: $rdh;
    text-decoration: none;
}

strong {
    font-weight: bold;
}

/*.root {
    transition: .3s;
    opacity: 0;
    
    &.doc-ready {
        opacity: 1;
    }
}*/

h1 {
    @include h(1);
    margin: 0 0 30px;

    @include xl {
        margin-bottom: 20px;
    }
}

h2 {
    @include h(2);
    margin: 0 0 30px;

    @include xl {
        margin-bottom: 20px;
    }
}

h3 {
    @include h(3);
    margin: 0 0 20px;

    @include xl {
        margin-bottom: 15px;
    }
}

h4 {
    @include h(4);
    margin: 0 0 30px;

    @include xl {
        margin-bottom: 20px;
    }
}

h5 {
    @include h(5);
    margin: 0 0 10px;
}

p + h1,
p + h2,
p + h3,
p + h4 {
    margin-top: 60px;

    @include sm {
        margin-top: 40px;
    }
}

.hidden {
    visibility: hidden;
}

.none {
    display: none;
}

.link {
    color: $b;
    text-decoration: underline;

    &:hover {
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;

    tr:nth-child(even) {
        background: #f8fafc;
    }

    th {
        color: $b;
        padding: 15px 15px;
        border: 0;
        font-weight: normal;
    }

    td {
        padding: 15px 15px;
        border: 0;
        border-bottom: 0;
    }
}

.table {
    min-width: 500px;

    @include sm {
        font-size: 14px;
    }

    &-wrap {
        overflow: auto;
        display: block;

        @include sm {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    th {
        color: $b;
        opacity: 0.6;
        padding: 15px 15px;
        font-weight: normal;
        border-bottom: 0;

        p {
            margin: 0;
        }
    }

    td {
        padding: 15px 15px;
        border-bottom: 0;

        p {
            margin: 0;
        }
    }
}

.tabs {
    width: 100%;

    &__wrap {
        @include md {
            position: relative;
            overflow-y: hidden;
            overflow-x: scroll;
            margin: 0 -30px;
        }
        @include sm {
            margin: 0 -15px;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        @include flex(lc);
        width: 100%;

        @include md {
            display: inline-block;
            white-space: nowrap;
        }

        .btn {
            margin-left: auto;
            margin-right: 10px;
        }
    }

    &__item {
        list-style: none;
        display: inline-block;
        margin-right: 5px;

        @include md {
            width: auto;
        }

        &:last-child {
            margin-right: 0;

            @include md {
                margin-right: 30px;
            }
            @include sm {
                margin-right: 15px;
            }
        }

        &:first-child {
            @include md {
                margin-left: 30px;
            }
            @include sm {
                margin-left: 15px;
            }
        }
    }

    &__link {
        display: block;
        color: $b;
        padding: 10px 40px;
        position: relative;
        white-space: normal;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        color: $b;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px 4px 0 0;

        @include xl {
        }
        @include lg {
            min-width: inherit;
        }

        &:hover {
            color: $rd;
            text-decoration: none;
        }

        &.active {
            color: $rd;
            background: $w;
        }
    }

    &__content {
        background: $w;
        overflow: hidden;
        border-top: 0;

        @include md {
            margin-left: -30px;
            padding-left: 30px;
            margin-right: -30px;
            padding-right: 30px;
        }
        @include sm {
            margin-left: -15px;
            padding-left: 15px;
            margin-right: -15px;
            padding-right: 15px;
        }
    }

    &__content > &__pane {
        display: block;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.2s;

        @include md {
            margin-left: -30px;
            padding-left: 30px;
            margin-right: -30px;
            padding-right: 30px;
        }
        @include sm {
            margin-left: -15px;
            padding-left: 15px;
            margin-right: -15px;
            padding-right: 15px;
        }
    }

    &__content > &__pane.active {
        height: auto;
        opacity: 1;
    }
}

.btabs {
    width: 100%;

    &__wrap {
        @include md {
            position: relative;
            overflow-y: hidden;
            // overflow-x: scroll;
            margin: 0 -20px;
        }
        @include sm {
            margin: 0 -12px;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        @include flex(lc);
        width: 100%;
        margin-bottom: 20px;
        overflow-y: hidden;
        overflow-x: auto;

        @include md {
            padding: 10px 0;
            white-space: nowrap;
        }
        @include sm {
            margin-bottom: 0px;
        }
        .btn {
            margin-left: auto;
            margin-right: 10px;
        }
    }

    &__item {
        list-style: none;
        display: inline-block;

        .btabs-full & {
            width: 100%;
        }
        @include md {
            width: auto;
        }
        &:last-child {
            margin-right: 0;

            @include md {
                margin-right: 20px;
            }
            @include sm {
                margin-right: 12px;
            }
        }
        &:first-child {
            @include md {
                margin-left: 20px;
            }
            @include sm {
                margin-left: 12px;
            }
        }
    }

    &__link {
        font-weight: bold;
        letter-spacing: 0.5px;
        border-bottom: 2px solid $a1;
        color: $b3;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        display: inline-block;

        .btabs-full & {
            width: 100%;
        }
        @include xl {
        }
        @include lg {
            min-width: inherit;
        }
        &:hover {
            color: $rd;
            text-decoration: none;
        }
        &.active {
            color: $b;
            border-color: $rd !important;
        }
    }

    &__content {
        background: $w;
        overflow: hidden;
        border-top: 0;

        @include md {
            margin-left: -20px;
            padding-left: 20px;
            margin-right: -20px;
            padding-right: 20px;
        }
        @include sm {
            margin-top: 20px;
            margin-left: -12px;
            padding-left: 12px;
            margin-right: -12px;
            padding-right: 12px;
        }
    }

    &__content > &__pane {
        display: block;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.2s;

        @include md {
            // margin-left: -20px;
            padding-left: 20px;
            margin-right: -20px;
            padding-right: 20px;
        }
        @include sm {
            // margin-left: -12px;
            padding-left: 12px;
            margin-right: -12px;
            padding-right: 12px;
        }
    }

    &__content > &__pane.active {
        height: auto;
        opacity: 1;
    }
}

.slider-controls {
    position: absolute;
    bottom: 0;
    right: 0;
    @include flex(lc);
    z-index: 4;

    &__count {
        @include flex(lc);

        @include md {
            display: none;
        }
        .swiper-pagination {
            position: relative;
            bottom: auto;
            left: auto;
            width: auto;
            color: $w;
            @include flex(lc);
        }
        .swiper-pagination-current {
            @include h(3);
            margin-right: 8px;

            &:before {
                content: "0";
            }
        }
        .swiper-pagination-total {
            @include h(6);
            opacity: 0.5;
            margin-left: 4px;

            &:before {
                content: "0";
            }
        }
        &:after {
            width: 150px;
            height: 2px;
            background: $rd;
            display: block;
            margin: 0 20px;
            content: "";

            @include xxl {
                width: 100px;
            }
            @include xl {
                width: 80px;
            }
            @include lg {
                margin: 0;
                width: 10px;
                background-color: transparent;
            }
        }
    }
    &__btns {
        @include flex(lc);

        .swiper-button-prev,
        .swiper-button-next {
            width: 60px;
            height: 90px;
            background: rgba(0, 0, 0, 0.3);
            display: block;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            margin: 0;
            margin-left: 1px;

            @include xxl {
                width: 50px;
                height: 80px;
            }
            @include xl {
                height: 70px;
            }
            @include xs {
                height: 44px;
                width: 44px;
            }
            &:after {
                width: 100%;
                height: 100%;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                filter: grayscale(1) brightness(8);
                transition: 0.2s;
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzE0MDM5IDkuMjQyNDJDMC4zMTk2ODYgOS4yNDI0MiAwIDkuNTYyMTEgMCA5Ljk1NjQ2QzAgMTAuMzQ5MyAwLjMxNzM0OCAxMC42Njg0IDAuNzEwMTk0IDEwLjY3MDVMMTYuODgzMSAxMC43NTc2TDEzLjAzNDggMTQuMzQ5M0MxMi43MDE3IDE0LjY2MDIgMTIuNzAxNyAxNS4xODgzIDEzLjAzNDggMTUuNDk5MkMxMy4zMzcgMTUuNzgxMiAxMy44MDU5IDE1Ljc4MTIgMTQuMTA4IDE1LjQ5OTJMMTkuNjA4NCAxMC4zNjU1QzE5LjgyMDIgMTAuMTY3OSAxOS44MjAyIDkuODMyMTQgMTkuNjA4NCA5LjYzNDQ3TDE0LjEwOCA0LjUwMDgzQzEzLjgwNTkgNC4yMTg4MiAxMy4zMzcgNC4yMTg4MiAxMy4wMzQ4IDQuNTAwODNDMTIuNzAxNyA0LjgxMTc0IDEyLjcwMTcgNS4zMzk3OCAxMy4wMzQ4IDUuNjUwNjlMMTYuODgzMSA5LjI0MjQySDAuNzE0MDM5WiIgZmlsbD0iI0YxMEIxMyIvPgo8L3N2Zz4K")
                    center no-repeat;
            }
            &:hover:after {
                filter: grayscale(0) brightness(1);
            }
        }
        .swiper-button-prev {
            transform: scaleX(-1);
        }
    }
}

.rc-slider {
    position: relative;

    .rc-slider-rail {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $gr;
    }
    .rc-slider-track {
        position: absolute;
        @extend .catalog-filter__sliderline;
    }
    .rc-slider-handle {
        @extend .catalog-filter__sliderdot;
    }
}

.rc-rate {
    .rc-rate-star {
        margin-right: 0;
        color: $a1;
    }
    .rc-rate-star-full {
        // @extend .catrating__star-1;

        .rc-rate-star-second {
            color: $rd;
        }

        &:hover {
            .rc-rate-star-second {
                color: $rd;
            }
        }
    }
    // .rc-rate-star-zero {
    //     @extend .catrating__star-0;
    // }

    &.size--sm {
        font-size: 18px;
    }

    &.size--md {
        font-size: 25px;
    }
}

.datepicker {
    @extend .form-input;
}

.react-datepicker {
    border-color: $a2;

    &__triangle {
        display: none;
    }

    &__header {
        border: none;
        background-color: $a1;
    }

    &__month-container {
        background-color: $w5;
    }

    &__day {
        width: 2rem;
        padding: 0.2rem;

        &:where([aria-disabled="false"]):hover {
            background-color: $a1;
        }

        &--selected {
            background-color: $rd;

            &:hover {
                background-color: $rda;
            }
        }

        &--keyboard-selected {
            background-color: $b3;
        }
    }
}

.date-input {
    display: flex;
    column-gap: 15px;
}

.inner-html {
    & * {
        white-space: pre-wrap;
    }
}

.uppercase {
    text-transform: uppercase;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: load 1500ms 0.4s infinite;
    animation-direction: alternate-reverse;
    z-index: 100;
}

.loader-overlay {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $w;
    z-index: 99;
}

@keyframes load {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.alert {
    padding: 10px;
    background: $a0;
    border: 1px solid $a1;
    margin-bottom: 10px;
}

.back-to-top {
    &__container {
        z-index: 10;
        position: fixed;
        right: 20px;
        bottom: 90px;

        @include lg {
            bottom: 150px;
        }
    }
    &__icon {
        margin: auto;
        transform: rotate(-90deg) scale(1.4);
        height: 30px;
        width: 17px;
        color: $w;
    }
}
