:root {
    --color-white: #fff;
    --color-white-25: rgba(255, 255, 255, 0.25);
    --color-white-30: rgba(255, 255, 255, 0.3);
    --color-white-50: rgba(255, 255, 255, 0.5);
    --color-white-70: rgba(255, 255, 255, 0.7);
    --color-black: #1f1a1a;
    --color-black-30: #8f8c8c;
    --color-black-70: #625e5e;
    --color-gray: #f2f2f2;
    --color-background: #2c3746;
    --color-background--hover: #515e6f;
    --color-background--disabled: #16324f;
    --color-light-gray: #a8a3a3; /* Новый цвет */
    --color-primary: #ed1d24;
    --color-primary--hover: #f14046;
    --color-primary--active: #f10b13;
    --color-primary--disabled: #f29a9d;
    --color-accent-1: #fef8f4;
    --color-accent-2: #fceadc;
    --color-accent-3: #fadbc2;
    --color-accent-4: #f8caa5;
    --color-accent-5: #d36e1a;
    --color-success: #3ad441;
    --color-error: #ed1d24;
}

[data-theme="BLACK_DIAMOND"] {
    --color-background: #252525;
    --color-background--hover: #2f2f2f;
    --color-background--disabled: #212121;
    --color-primary: #00a86b;
    --color-primary--hover: #00bd71;
    --color-primary--active: #119766;
    --color-primary--disabled: #6d6c6c;
    --color-accent-1: #f1f1f1;
    --color-accent-2: #d4d4d4;
    --color-accent-3: #b3b3b3;
    --color-accent-4: #a7a7a7;
    --color-accent-5: #979797;
}

$w: var(--color-white);
$b: var(--color-black);
$w25: var(--color-white-25);
$w3: var(--color-white-30);
$w5: var(--color-white-50);
$w7: var(--color-white-70);
$b3: var(--color-black-30);
$b7: var(--color-black-70);
$gr: var(--color-gray);
$bd: var(--color-background--disabled);
$bg: var(--color-background);
$bgh: var(--color-background--hover);
$rd: var(--color-primary);
$rdh: var(--color-primary--hover);
$rda: var(--color-primary--active);
$rdd: var(--color-primary--disabled);
$a0: var(--color-accent-1);
$a1: var(--color-accent-2);
$a2: var(--color-accent-3);
$a3: var(--color-accent-4);
$a4: var(--color-accent-5);
$er: var(--color-error);
$l2: var(--color-success);
$ltg: var(--color-light-gray);

$xxxs-width: 321.98px;
$xxs-width: 359.98px;
$xs-width: 567.98px;
$sm-width: 767.98px;
$md-width: 991.98px;
$lg-width: 1129.98px;
$xl-width: 1469.98px;
$xxl-width: 1749.98px;
